import styled from "@emotion/styled"
import { Search, X } from "react-feather"
import { connectSearchBox } from "react-instantsearch-dom"
import { SEARCH_PLACEHOLDER } from "../../utils/const"
import { black } from "../../utils/variables"
import { TextInput } from "../UI/input"

const _SearchBox = ({ currentRefinement, refine }) => {
  const button = currentRefinement ? (
    <SearchInputButton onClick={() => refine("")}>
      <X />
    </SearchInputButton>
  ) : (
    <SearchInputButton type="button">
      <Search />
    </SearchInputButton>
  )
  return (
    <form noValidate action="" role="search">
      <SearchInputWrapper>
        <TextInput
          type="text"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
          placeholder={SEARCH_PLACEHOLDER}
        />
        {button}
      </SearchInputWrapper>
    </form>
  )
}

const SearchBox = connectSearchBox(_SearchBox)

export const SearchInputWrapper = styled.div`
  position: relative;
  display: inline-block;
`

export const SearchInputButton = styled.button`
  position: absolute;
  outline: 0;
  border: 0;
  right: 14px;
  top: 18px;
  background: transparent;
  cursor: pointer;
  color: ${black}D4;
`

export default SearchBox
